<template>
  <screen-adapter :w="3840" :h="2160">
    <div class="index1">
      <c-header :bg-img="bgImg" @on-click="$router.go(-1)"></c-header>
      <div class="index1-content">
        <div class="index1-i index1-t">
          <div class="index1-i-h">
            <p class="index1-title">{{ hqData.title }}</p>
            <div class="index1-i-h-t">
              <div class="index1-i-h-l">
                <dash-multi
                  ref="dashMulti"
                  :series-data="hqData.top3Data"
                ></dash-multi>
              </div>
              <div class="index1-i-h-r">
                <div class="ev-table-container">
                  <ev-list :list="hqData.tableData"></ev-list>
                </div>
              </div>
            </div>
          </div>
          <div class="index1-i-h">
            <p class="index1-title">{{ hqRunData.title }}</p>
            <line-multi
              ref="lineMulti"
              :x-data="hqRunData.xData"
              :y-data="hqRunData.yData"
            ></line-multi>
          </div>
        </div>
        <div class="index1-i index1-m">
          <div class="index1-i-h">
            <p class="index1-title">{{ EHS3SalesData.title }}</p>
            <bar-single
              ref="barSingle"
              :x-data="EHS3SalesData.xData"
              :y-data="EHS3SalesData.yData"></bar-single>
          </div>
          <div class="index1-i-h index1-i-h-h">
            <div class="index1-i-h-i">
              <p class="index1-title">{{ batteryBuyData.title }}</p>
              <pie-solid
                ref="pieSolid1"
                :series-data="batteryBuyData.seriesData"
              ></pie-solid>
            </div>
            <div class="index1-i-h-i">
              <p class="index1-title">{{ batteryTypeData.title }}</p>
              <pie-solid
                ref="pieSolid2"
                :series-data="batteryTypeData.seriesData"
              ></pie-solid>
            </div>
          </div>
          <div class="index1-i-h">
            <p class="index1-title">{{ BData.title }}</p>
            <bar-multi
              ref="barMulti"
              :x-data="BData.xData"
              :y-data1="BData.yData1"
              :y-data2="BData.yData2"
              :y-data3="BData.yData3"
            ></bar-multi>
          </div>
        </div>
        <div class="index1-i index1-b">
          <div class="index1-i-h">
            <p class="index1-title">{{ B30UserData.title }}</p>
            <bar-overlay
              ref="barOverlay"
              :x-data="B30RunData.xData"
              :y-data1="B30RunData.yData1"
              :y-data2="B30RunData.yData2"
            ></bar-overlay>
          </div>
          <div class="index1-i-h">
            <p class="index1-title">{{ B30SaleData.title }}</p>
            <div class="sale-list-container" v-if="B30SaleData.data.length">
              <ul class="sale-list">
                <li class="sale-item sale-item-title">
                  <p class="sale-item-i sale-item-1">采购方</p>
                  <p class="sale-item-i sale-item-2">采购数量</p>
                  <p class="sale-item-i sale-item-3">电池数量</p>
                  <p class="sale-item-i sale-item-4">已有销售信息</p>
                  <p class="sale-item-i sale-item-5">采购方未反馈信息</p>
                </li>
                <li class="sale-item" v-for="(item,index) in B30SaleData.data" :key="index">
                  <p class="sale-item-i sale-item-1">{{ item.purchaser }}</p>
                  <p class="sale-item-i sale-item-2">{{ item.purchaseQty }}</p>
                  <ul class="sale-item-i sale-item-3">
                    <li class="sale-item-3-i" v-for="(it,idx) in item.batteryInfo" :key="idx">
                      <p class="sale-item-3-i-l">{{ it.name }}: </p>
                      <p class="sale-item-3-i-r">{{ it.value }}</p>
                    </li>
                  </ul>
                  <p class="sale-item-i sale-item-4">{{ item.salesQty }}</p>
                  <p class="sale-item-i sale-item-5">{{ item.unSalesQty }}</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="index1-i-h">
            <p class="index1-title">{{ B30UserData.title }}</p>
            <bar-side
              ref="barSide"
              :x-data="B30UserData.xData"
              :y-data1="B30UserData.yData1"
              :y-data2="B30UserData.yData2"></bar-side>
          </div>
        </div>
      </div>
    </div>
  </screen-adapter>
</template>
<script>
import screenAdapter from '../components/screen-adapter'
import cHeader from '../components/c-header'
import evList from '../components/ev-list'
import dashMulti from '../components/dash-multi'
import lineMulti from '../components/line-multi'
import pieSolid from '../components/pie-solid'
import barSingle from '../components/bar-single'
import barMulti from '../components/bar-multi'
import barOverlay from '../components/bar-overlay'
import barSide from '../components/bar-side'
import { getSalesData } from '../api'
import { pageStep } from '../config'

export default {
  components: {
    screenAdapter,
    cHeader,
    evList,
    dashMulti,
    pieSolid,
    barSingle,
    barMulti,
    barOverlay,
    barSide,
    lineMulti
  },
  data () {
    return {
      timer: null,
      hqData: {
        title: ''
      },
      hqRunData: {
        title: '',
        xData: [],
        yData: []
      },
      BData: {
        title: '',
        xData: [],
        yData1: [],
        yData2: [],
        yData3: []
      },
      EHS3SalesData: {
        xData: [],
        yData: []
      },
      batteryTypeData: {
        title: '',
        seriesData: []
      },
      batteryBuyData: {
        title: '',
        seriesData: []
      },
      B30SaleData: {
        title: '',
        data: []
      },
      B30RunData: {
        title: '',
        xData: [],
        yData1: [],
        yData2: []
      },
      B30UserData: {
        title: '',
        xData: [],
        yData1: [],
        yData2: []
      },
      bgImg: require('../assets/images/header-title1.png'),
      screenWidth: document.documentElement.offsetWidth
    }
  },
  created () {
    this.getSalesData()
    if (this.timer) {
      clearInterval(this.timer)
    } else {
      this.timer = setInterval(() => {
        // this.getSalesData()
        // 数据是否需要实时获取
        this.resetChart()
      }, pageStep * 1000)
    }
  },
  destroyed () {
    clearInterval(this.timer)
  },
  methods: {
    resetChart () {
      this.$refs.dashMulti.init()
      this.$refs.pieSolid1.init()
      this.$refs.pieSolid2.init()
      this.$refs.barMulti.init()
    },
    getSalesData () {
      getSalesData().then(({ result }) => {
        const { hongqiEV, hongqiEVRun, es3, bplus, batteryBuy, batteryType, b30Table, b30, b302 } = result
        // 1.红旗EV销售上牌数据
        const hqEvData = hongqiEV.data
        const hqEvMax = hqEvData[0].qty
        hqEvData.forEach((item) => {
          item.percent = `${Math.ceil((item.qty / hqEvMax) * 10000) / 100}%`
        })
        this.hqData.title = hongqiEV.title
        this.hqData.tableData = hqEvData
        this.hqData.top3Data = hqEvData.slice(0, 3)

        // 2.红旗EV总体运行情况
        this.hqRunData.title = hongqiEVRun.title
        this.hqRunData.xData = hongqiEVRun.x
        this.hqRunData.yData = hongqiEVRun.data

        // 3,E-HS3销售城市
        this.EHS3SalesData.title = es3.title
        this.EHS3SalesData.xData = es3.x
        this.EHS3SalesData.yData = es3.data

        // 4.1车辆采购数量
        this.batteryBuyData.title = batteryBuy.title
        this.batteryBuyData.seriesData = batteryBuy.data

        // 4.2电池种类装机情况
        this.batteryTypeData.title = batteryType.title
        this.batteryTypeData.seriesData = batteryType.data

        // 5.B+客户车辆数据
        this.BData.title = bplus.title
        this.BData.xData = bplus.x
        this.BData.yData1 = bplus.data1
        this.BData.yData2 = bplus.data2
        this.BData.yData3 = bplus.data3

        // 6.B30EV400车辆分布及用户数据
        this.B30RunData.title = b30.title
        this.B30RunData.xData = b30.x
        this.B30RunData.yData1 = b30.data1
        this.B30RunData.yData2 = b30.data2

        // 7.B30EV400销售情况
        this.B30SaleData.title = b30Table.title
        this.B30SaleData.data = b30Table.data
        this.initIndex1()

        // 8.B30EV400车辆分布及用户数据
        this.B30UserData.title = b302.title
        this.B30UserData.xData = b302.x
        this.B30UserData.yData1 = b302.data1
        this.B30UserData.yData2 = b302.data2
        this.initIndex1()
      })
    },
    initIndex1 () {
      this.$nextTick(() => {
        this.$refs.dashMulti.init()
        this.$refs.lineMulti.init()
        this.$refs.barSingle.init()
        this.$refs.pieSolid1.init()
        this.$refs.pieSolid2.init()
        this.$refs.barMulti.init()
        this.$refs.barOverlay.init()
        this.$refs.barSide.init()
      })
    }
  }
}
</script>
<style lang="less" scoped>
.ev-table-container {
  padding: 60px 40px 20px 0;
  box-sizing: border-box;
  margin-left: -80px;
}

.sale-list-container {
  padding: 30px 50px 0;
}

.sale-list {
  flex: 1;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  margin-left: -20px;

  .sale-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    padding: 8px 0;

    &:nth-child(odd) {
      background: #242628;
    }

    &.sale-item-title {
      font-size: 28px;
      font-weight: bold;
      color: #00A8FF;
      background: #272C38;
      padding: 30px 0;
    }

    .sale-item-i {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .sale-item-1 {
      flex: 0 0 11%;
    }

    .sale-item-2 {
      flex: 0 0 15%;
    }

    .sale-item-3 {
      flex: 0 0 20%;
      flex-direction: column;

      .sale-item-3-i {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        width: 100%;
        padding: 5px 0;

        .sale-item-3-i-l {
          flex: 0 0 50%;
          text-align: right;
          padding-right: 6px;
        }

        .sale-item-3-i-r {
          flex: 0 0 50%;
        }
      }
    }

    .sale-item-4 {
      flex: 0 0 24%;
    }

    .sale-item-5 {
      flex: 0 0 25%;
    }
  }
}

.index1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: url('../assets/images/bg1.png') no-repeat;
  background-size: cover;

  .index1-title {
    font-size: 50px;
    font-weight: bold;
    font-family: "微软雅黑";
    color: #65CCFF;
    text-align: center;
    width: 100%;
  }

  .index1-content {
    flex: 1;
    padding: 30px 64px 0;
  }

  .index1-i {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.index1-t {
      .index1-i-h {
        height: 632px;
        background: url("../assets/images/card-bg2.png") no-repeat;
      }
    }

    &.index1-m {
      .index1-i-h {
        padding: 100px 74px 80px;
        height: 662px;
        background: url("../assets/images/card-bg1.png") no-repeat;
      }
    }

    &.index1-b {
      .index1-i-h {
        padding: 100px 74px 80px;
        height: 662px;
        background: url("../assets/images/card-bg1.png") no-repeat;
      }
    }

    .index1-i-h {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      flex: 1;
      background-size: cover;
      padding: 90px 74px 80px;

      &.index1-i-h-h {
        flex-direction: row;
      }

      .index1-i-h-t {
        flex: 1;
        display: flex;
      }

      .index1-i-h-l {
        flex: 0 0 66%;
      }

      .index1-i-h-r {
        flex: 0 0 33%;
      }

      .index1-i-h-i {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
</style>
